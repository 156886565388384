import type { Locale, LocalizedString } from "../../../core/schema/Locale.js";
import { getGlobalSettings } from "../getGlobalSettings.js";

function translate<Translations extends Record<string, LocalizedString>, Key extends keyof Translations>(
	translations: Translations,
	key: Key,
	locale: Locale,
	variables: Record<string, string> = {},
): Translations[Key][Locale] {
	const translation = translations[key]?.[locale];
	let finalTranslation = String(translation ?? key);
	for (const [variable, value] of Object.entries(variables)) {
		finalTranslation = finalTranslation.replaceAll(`{{${variable}}}`, value);
	}
	return finalTranslation as Translations[Key][Locale];
}

export function getTranslate<Translations extends Record<string, LocalizedString>>(translations: Translations) {
	const { TENANT_LOCALE } = getGlobalSettings();
	return <Key extends keyof Translations>(
		key: Key,
		variables: Record<string, string> = {},
	): Translations[Key][Locale] => {
		return translate<Translations, Key>(translations, key, TENANT_LOCALE, variables);
	};
}
