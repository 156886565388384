<script lang="ts">
	import Layout from "../components/Layout.svelte";
	import Link from "../components/Link.svelte";
	import { nbspify } from "../../../core/utils/nbspify.js";
	import translations from "./NotFound.translations.json";
	import { getTranslate } from "../utils/getTranslate.js";

	const translate = getTranslate(translations);
</script>

<Layout>
	<section class="container mt-20 md:mt-32">
		<h1 class="text-center">{nbspify(translate("title"))}</h1>
		<div class="mt-8 text-center">
			<p>{nbspify(translate("content"))}</p>
			<p>
				{nbspify(translate("makeSure"))}
			</p>
			<Link asButton href="/" class="mx-auto mt-12 block group-hover:before:!w-full max-2xl:!px-4">
				{nbspify(translate("back"))}
			</Link>
		</div>
	</section>
</Layout>
